import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const getRandomOrder = (arr) => {
  const clone = arr.slice()
  const ret = []
  while (clone.length > 0) {
    const obj = clone.splice(Math.floor(Math.random() * clone.length), 1)[0]
    ret.push(Object.assign({}, obj))
  }
  return ret.slice(0, 3)
}

const MoreArticles = (props) => {
  const articles = props.articles
  const randomized = getRandomOrder(articles)
  return (
    <>
      {randomized &&
        <div className="container mx-auto">
          <div className="pb-5 lg:pb-6 lg:text-center">
            <h3 className="text-rp1 sm:text-h3 lg:text-h3 font-poppings-bold text-term-primary">
              More Articles
            </h3>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-5 lg:gap-x-6-6">

            {randomized.map(({ node: post }, index) => (
              <div key={index} className="mb-35 lg:mb-0">
                <Link to={post.fields.slug}>
                  <div>
                    {post.frontmatter.featured_image?.image && <GatsbyImage image={getImage(post.frontmatter.featured_image?.image)} alt={post.frontmatter.featured_image.alt} />}
                  </div>
                  <div className="pt-3-3 lg:pt-6 ">
                    <h3 className="pr-6-6 sm:pr-0 lg:pr-0 pb-1-1 lg:pb-5-1 text-rp1 lg:text-rh1 xl:text-h3 font-worksans-normal text-term-primary">{post.frontmatter.title}</h3>
                    <p className="mb-0-2 lg:mb-2 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">{post.frontmatter.blog_category}</p>
                    <span className="text-term-primary font-worksans-normal text-p4 lg:text-p3">{post.frontmatter.date}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
}

export default MoreArticles;