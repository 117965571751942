import React, { useRef, useState } from "react"
import Axios from "axios"
import { Link, useStaticQuery, graphql } from "gatsby"


const StayLoop = () => {
  const [email, setEmail] = useState("")
  const [isShown, setIsShown] = useState(false)
  const [isShownSuccessbtn, setIsShownSuccessbtn] = useState(false)
  const [isShownMainbtn, setIsShownMainbtn] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const [isDisable, setIsDisable] = useState(true)
  const [errMessage, setErrMessage] = useState("")
  const inputRef = useRef(null);

  function handleClick() {
    setIsShown(true)
    setIsShownMainbtn(true)
    inputRef.current.focus();
  }
  function handleSubmit(e) {
    e.preventDefault();
    setIsDisable(true)
     Axios.post(`${process.env.API_LINK}api/newsletter/create`, { "email": email })
       .then(result => {
         if (result.data.status) {
    setIsShown(false)
    setIsHide(true)
    setIsShownSuccessbtn(true)
    setTimeout(() => {
      setIsShownMainbtn(false)
      setIsShownSuccessbtn(false)
      setIsHide(false)
      setEmail("")
    }, 1000);
      } else {
        setErrMessage("Something went wrong try again!!!")
      }
    })
    .catch(err => {
      setErrMessage(err)
    })

  }
  function handleChange(e) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const value = e.target.value;
    if (emailPattern.test(value)) {
      setEmail(value)
      setIsDisable(false)
    } else {
      setEmail(value)
      setIsDisable(true)
    }
  }

  const data = useStaticQuery(graphql`
  query {
      content: markdownRemark(fileAbsolutePath: {regex: "/stayIn-loop.md/"}) {
        frontmatter {
          sub_title
          description
        }
      }
    }
  `)
  const info = data.content.frontmatter
  return (
    <>
      <section>
        <div className="container mx-auto bg-background-secondary container-full">
          <div className="bg-dots-vector relative">
            <div className="pt-61 lg:px-100 lg:pt-1-06 pr-8 xl:pr-1-80 pb-49 lg:pb-1-22 pl-38 xl:pl-40 dots-vector-right-top items-center flex flex-wrap justify-between flex-wrap">
              <div className="w-full md:w-2/3">
                <h6 className="pb-3 md:pb-0 font-poppings-bold uppercase text-rh6 lg:text-h6 text-cyan">
                  {info.sub_title}
                </h6>
                <p className="pb-4-3 md:pb-0 font-worksans-bold text-rp1 lg:text-p1 text-white">
                  {info.description}
                </p>
              </div>
              <div className="animated-sigh-up  relative">
                <div className={`${isShown ? "expand-b" : ""} ${isHide ? "hide" : ""} ml-auto news-letter-container`}>
                  <button type="button" id="trigger" className={`${isShownMainbtn ? "" : "shown"} news-letter--btn w-full z-30 font-poppings-bold text-rh5 lg:text-h5 text-cyan absolute inset-0 transform scale-0 opacity-0`} onClick={handleClick}>
                    Sign Up
                  </button>
                  <form id="news-letter-form" onSubmit={handleSubmit}>
                    <div id="news-letter--input" className="z-20 absolute inset-0 transform scale-100 opacity-100">
                      <input type="text" placeholder="E-mail" name="email" value={email} onChange={handleChange} ref={inputRef} className={`${isShown ? "shown" : ""} ${isHide ? "hide" : ""} text-white font-poppings-normal text-p3 placeholder-text-term-secondary`} />
                      <button type="submit" disabled={isDisable} className={`${isShown ? "shown" : ""} ${isHide ? "hide" : ""} w-1/5 font-poppings-bold text-rh5 lg:text-h5 text-white bg-cyan`}>
                        Send
                      </button>
                    </div>
                    <div id="success" className={`${isShownSuccessbtn ? "shown" : ""} flex justify-center items-center z-10 font-poppings-bold text-rh5 lg:text-h5 text-cyan absolute inset-0 transform scale-0 opacity-0`}>
                      Thank you!
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StayLoop;

